
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/dist/css/bootstrap";
@import "~vuetify/dist/vuetify.min.css";
@import "~vue-snotify/styles/material";
@import "~vue2-dropzone/dist/vue2Dropzone.min.css";

//Font awesome
@import "~@fortawesome/fontawesome-free/css/fontawesome.min.css";
@import "~@fortawesome/fontawesome-free/css/solid.min.css";
@import "~material-design-icons-iconfont/dist/material-design-icons.css";

//Pace
@import "~noty/src/noty.scss";

@import '~dropzone/dist/min/dropzone.min.css';
@import '~handsontable/dist/handsontable.full.css';

@import '~leaflet/dist/leaflet.css';

[v-cloak] { display: none; }
